if (window.location.hostname.includes('hexagonian.com') && location.protocol != 'https:') {
	location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

$(document).ready(function() {

	$("#toggle").on('click', function() {
		$(this).toggleClass('active');
		$('#overlay').toggleClass('open');
	});

});
